import { Alert as AlertBox, AlertDescription, AlertIcon, Collapse, Flex } from '@chakra-ui/react'

type Props = {
  status: 'loading' | 'success' | 'error' | 'warning' | 'info'
  description: string
  isVisible: boolean
}

const alertStyle = {
  loading: {
    bg: 'brand.blue',
    color: 'white',
  },
  success: {
    bg: 'brand.green-right',
    color: 'brand.green',
  },
  error: {
    bg: 'brand.red-right',
    color: 'red.500',
  },
  warning: {
    bg: 'brand.yellow-right',
    color: 'brand.yellow',
  },
  info: {
    bg: 'brand.blue-right',
    color: 'brand.blue',
  },
}

/**
 * Based on Chakra UI official sample codes
 * See: https://chakra-ui.com/docs/components/alert
 */
export const Alert: React.FC<Props> = (props: Props) => {
  const { status, description, isVisible } = props

  return (
    <Collapse in={isVisible}>
      <AlertBox w="full" p="8px" status={status} bg={alertStyle[status].bg}>
        <Flex alignItems="flex-start">
          <AlertIcon color={alertStyle[status].color} />
          <AlertDescription
            fontSize="14px"
            lineHeight="24px"
            color={status === 'error' ? '#E53E3E' : 'text.primary'}
          >
            {description}
          </AlertDescription>
        </Flex>
      </AlertBox>
    </Collapse>
  )
}
