import { Box, Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'

import styles from '@/components/top/top.module.scss'
import { ChakraLink } from '@/modules/components/ChakraLink'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { jobCategoryImageSwitcher } from '@/utils/topImageSwitcher'

const PopularJobCategoryElm = (data: {
  name: string
  jobCategoryMasterId: number
  subStr?: string
  bottomSpace: string
}) => {
  const { name, jobCategoryMasterId, subStr, bottomSpace } = data
  const handleClick = () => {
    // TODO: ここでGTMのイベントを発火させる
  }
  return (
    <ChakraLink
      className={styles['job-category-box']}
      cursor="pointer"
      onClick={() => handleClick()}
      textDecoration="none"
      href={`/job/jobCategory_${jobCategoryMasterId}`}
      linkTo="talent"
      _hover={{ textDecoration: 'none' }}
      position="relative"
      h="160px"
      data-gtm-click-item={`popular_job_type_card_${jobCategoryMasterId}` as GTMClickItemName}
    >
      <Image
        alt={name}
        className={styles['job-category-image']}
        width={120}
        height={160}
        src={jobCategoryImageSwitcher(jobCategoryMasterId)}
      />
      <Box
        position="absolute"
        top={0}
        width={120}
        height={160}
        borderRadius="8px"
        background="linear-gradient(180deg, rgba(26, 32, 44, 0.00) 0%, rgba(26, 32, 44, 0.50) 100%)"
      />
      <Box
        w="100%"
        textAlign="center"
        bottom={bottomSpace}
        left="50%"
        transform="translateX(-50%)"
        position="absolute"
        whiteSpace="pre-wrap"
      >
        <Text color="white" textAlign="center" fontWeight={600} fontSize="12px" lineHeight="130%">
          {name}
        </Text>
        {subStr && (
          <Text color="white" fontSize="10px" fontWeight={300} lineHeight="130%">
            {subStr}
          </Text>
        )}
      </Box>
    </ChakraLink>
  )
}

export const PopularJobCategoryList = () => {
  const displayJobCategories = [
    { name: '営業', jobCategoryMasterId: 1, bottomSpace: '24px' },
    { name: 'ITエンジニア', jobCategoryMasterId: 6, bottomSpace: '24px' },
    { name: '事務・管理', jobCategoryMasterId: 2, bottomSpace: '24px' },
    { name: '建築・土木技術', jobCategoryMasterId: 13, bottomSpace: '24px' },
    {
      name: `サービス・接客
・店舗`,
      jobCategoryMasterId: 9,
      bottomSpace: '16px',
    },
    { name: 'マーケティング', jobCategoryMasterId: 3, bottomSpace: '24px' },
    {
      name: 'エンジニア',
      jobCategoryMasterId: 11,
      subStr: `機械・電気・電子・
半導体・制御`,
      bottomSpace: '8px',
    },
    {
      name: `技能工・設備
・交通・運輸`,
      jobCategoryMasterId: 14,
      bottomSpace: '16px',
    },
  ]
  return (
    <Flex className={styles['popular-job-category-item-boxes']}>
      {displayJobCategories.map((v) => (
        <PopularJobCategoryElm
          key={`popular-job-category-${v.jobCategoryMasterId}`}
          name={v.name}
          jobCategoryMasterId={v.jobCategoryMasterId}
          subStr={v.subStr}
          bottomSpace={v.bottomSpace}
        />
      ))}
    </Flex>
  )
}
