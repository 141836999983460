import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import type { GetServerSideProps, NextPage } from 'next'
import type { ErrorProps } from 'next/error'
import { ParsedUrlQuery } from 'querystring'

import AppLayout from '@/layout/AppLayout'
import { ErrorPageWrapper } from '@/modules/components/ErrorPageWrapper'
import serverSideLogger from '@/modules/utils/serverSideLogger'

type ErrorPageProps = ErrorProps & {
  errorMessage?: string
  hasGetInitialPropsRun?: boolean
}

const ErrorPage: NextPage<ErrorPageProps> = ({
  statusCode,
  errorMessage,
  hasGetInitialPropsRun = false,
}) => {
  useEffect(() => {
    if (!hasGetInitialPropsRun && errorMessage) {
      console.error(errorMessage)
      Sentry.captureException(errorMessage)
    }
  }, [hasGetInitialPropsRun, errorMessage])

  return (
    <AppLayout>
      <ErrorPageWrapper statusCode={statusCode} />
    </AppLayout>
  )
}

export const getServerSideProps: GetServerSideProps<ErrorPageProps, ParsedUrlQuery> = async (
  contextData,
) => {
  const { statusCode } = contextData.res
  let errorMessage = ''
  if (contextData.res) {
    if (contextData.res.statusCode) {
      errorMessage += `Status Code: ${contextData.res.statusCode} `
    }
    if (contextData.res.statusMessage) {
      errorMessage += `Status Message: ${contextData.res.statusMessage} `
    }

    const customErrorMessage = contextData.res.getHeader('X-Error-Message')
    if (customErrorMessage) {
      errorMessage += `Custom Error: ${customErrorMessage} `
    }

    if (!errorMessage) {
      errorMessage = `An unexpected error occurred on ${contextData.req.url}`
    }

    errorMessage = errorMessage.trim()
  }

  // resのなかにあるエラー情報をSentryに送信
  if (statusCode !== 404) {
    await Sentry.captureException(errorMessage)
    await Sentry.flush(2000)
    serverSideLogger.error({
      msg: {
        contextData: contextData,
        errorMessage: errorMessage,
      },
    })
  } else {
    serverSideLogger.info({
      msg: {
        contextData: contextData,
        errorMessage: errorMessage,
      },
    })
  }

  if ('statusCode' in contextData.query) {
    return {
      props: {
        errorMessage,
        hasGetInitialPropsRun: true,
        statusCode: Number(contextData.query.statusCode),
      },
    }
  }

  return { props: { statusCode, errorMessage, hasGetInitialPropsRun: true } }
}

export default ErrorPage
