import { Box, Flex, Text } from '@chakra-ui/react'

import Job1 from '@/assets/img/top/job/jobimg_1.jpg'
import Job2 from '@/assets/img/top/job/jobimg_2.jpg'
import Job3 from '@/assets/img/top/job/jobimg_3.jpg'
import Job4 from '@/assets/img/top/job/jobimg_4.jpg'
import Job5 from '@/assets/img/top/job/jobimg_5.jpg'
import Job6 from '@/assets/img/top/job/jobimg_6.jpg'
import styles from '@/components/top/top.module.scss'
import { ChakraNextImage } from '@/modules/components/ChakraNextImage'

export const ExplainOfScout = () => {
  const explainArray = [
    {
      img: Job1.src,
      title: '事務・オフィスワーク',
      tags: ['定時退社', '服装自由', 'データ入力'],
    },
    {
      img: Job3.src,
      title: '販売スタッフ',
      tags: ['残業なし', 'シフト制', 'コミュ力を活かせる'],
    },
    {
      img: Job5.src,
      title: '製造業',
      tags: ['ひとりでもくもく作業', '髪型自由', '手厚い福利厚生'],
    },
    {
      img: Job2.src,
      title: '営業',
      tags: ['土日祝休み', 'インセンティブ', '高収入を目指せる'],
    },
    {
      img: Job6.src,
      title: 'コールセンター',
      tags: ['服装自由', '髪型自由', '充実した研修'],
    },
    {
      img: Job4.src,
      title: '施工管理',
      tags: ['スキル身につく', '週休2日', '月収25~30万円'],
    },
  ]
  return (
    <Flex className={styles['explain-item-boxes']}>
      {explainArray.map((v) => (
        <Box key={v.title} className={styles['explain-item-box-wrapper']}>
          <Box className={styles['explain-item-box']}>
            <Box width="120px" height="80px">
              <ChakraNextImage className={styles['explain-image']} src={v.img} alt={v.title} />
            </Box>
            <Box>
              <Text mb="8px" textStyle="talent.heading.sm">
                {v.title}
              </Text>
              <Flex wrap="wrap" gap="4px">
                {v.tags.map((tag) => (
                  <Text
                    textStyle="talent.body.sm"
                    bg="surface.secondary"
                    p="2px 4px"
                    borderRadius="4px"
                    color="text.secondary"
                    key={tag}
                  >
                    #{tag}
                  </Text>
                ))}
              </Flex>
            </Box>
          </Box>
        </Box>
      ))}
    </Flex>
  )
}
