import { Box, Flex } from '@chakra-ui/react'

import styles from '@/components/top/top.module.scss'
import { ChakraLink } from '@/modules/components/ChakraLink'
import { TalentScout } from '@/oas/talent/api'

import { ScoutCard } from '../scout/parts/ScoutCard'

export const LikedScoutList = ({ scouts }: { scouts: TalentScout[] }) => {
  return (
    <Flex className={styles['popular-liked-scout-item-boxes']}>
      {scouts.map((scout) => (
        <Box key={scout.id} className={styles['liked-scout-box']}>
          <ChakraLink
            href={`/scout/${scout.id}`}
            linkTo="talent"
            textDecoration="none"
            _hover={{ textDecoration: 'none' }}
          >
            <ScoutCard scout={scout} isTop />
          </ChakraLink>
        </Box>
      ))}
    </Flex>
  )
}
