import { Box, Center, Text, VStack } from '@chakra-ui/react'
import type { ErrorProps } from 'next/error'

import { ErrorMassageObj } from '../const/ErrorMessage'
import { inquiryPage } from '../constant'

import { ChakraLink } from './ChakraLink'
import { MainButton } from './MainButton'

export const ErrorPageWrapper: React.FC<ErrorProps> = ({ statusCode }) => {
  return (
    <Center maxWidth="640px" margin="0 auto">
      <VStack>
        <Center p="74px 0">
          <VStack>
            <Text mb="12px" color="gray.400" fontSize="48px" lineHeight="48px" fontWeight={600}>
              {statusCode}
            </Text>
            <Text color="gray.400" fontSize="16px" fontWeight={600}>
              {ErrorMassageObj[statusCode]?.statusMessage}
            </Text>
          </VStack>
        </Center>
        <Box bg="white" p="24px 16px" color="gray.800" fontSize="16px">
          <Text mb="16px" fontWeight={700}>
            {ErrorMassageObj[statusCode]?.title}
          </Text>
          <Text whiteSpace="pre-wrap" mb="40px">
            {ErrorMassageObj[statusCode]?.description}
          </Text>
          <Center>
            <ChakraLink
              href={inquiryPage}
              linkTo="outside"
              isExternal
              target="_blank"
              _hover={{ textDecoration: 'none' }}
            >
              <MainButton mode="secondary" variant="outline" width="198px">
                お問い合わせ
              </MainButton>
            </ChakraLink>
          </Center>
        </Box>
      </VStack>
    </Center>
  )
}
