import { useEffect, useMemo, useState } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useAtomValue } from 'jotai'
import Image from 'next/image'
import { useRouter } from 'next/router'

import PencilIcon from '@/assets/icon/top/PencilAlt.svg'
import GuideBanner from '@/assets/img/top/bn_guide.png'
import Flow from '@/assets/img/top/fig_flow.png'
import GuideFig1 from '@/assets/img/top/fig-guide-1.png'
import GuideFig2 from '@/assets/img/top/fig-guide-2.png'
import FV from '@/assets/img/top/fv_202407_short_a.png'
import styles from '@/components/top/top.module.scss'
import { guideUrl, talentUrl } from '@/config'
import { DEFAULT_DELIVERY_HOUR } from '@/const/defaultDeliveryHour'
import { localStorageKey } from '@/const/localStorage'
import { registrationFormAgentToValues } from '@/const/registrationFormValues'
import { authMeAtom } from '@/globalState/auth'
import { LocationMarker } from '@/modules/assets/icon/LocationMarkerIcon'
import { Alert } from '@/modules/components/Alert'
import { ChakraLink } from '@/modules/components/ChakraLink'
import { ChakraNextImage } from '@/modules/components/ChakraNextImage'
import { MainButton } from '@/modules/components/MainButton'
import { useWindowSize } from '@/modules/hooks/useWindowSize'
import { getCharacterIconSource } from '@/modules/utils/character'
import { isLocal } from '@/modules/utils/env'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { PrefecturesWithJobCount } from '@/oas/public/api'
import { Scouts } from '@/oas/talent/api'
import { getDeliveryAnnounceText } from '@/usecases/scout/deliveryAnnounce'

import { OptionButton } from '../auth/registration/RegistrationForAgentPageWrapper'

import { ExplainOfScout } from './ExplainOfScout'
import { GuideSection } from './GuideSection'
import { LikedScoutList } from './LikedScoutList'
import { PopularJobCategoryList } from './PopularJobCategoryList'
import { PopularParticularConditionList } from './PopularParticularConditionList'
import { PopularPrefectureList } from './PopularPrefectureList'

const sliderProperty = {
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  WebkitOverflowScrolling: 'touch',
  /* IE,Edge,FireFoxでスクロールバーを非表示にする */
  MsOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  img: {
    scrollSnapAlign: 'center',
  },
}

type Props = {
  prefecturesWithJobCount: PrefecturesWithJobCount | null
  scouts: Scouts | null
}

const H2Section = ({
  type,
}: {
  type: 'prefecture' | 'jobCategory' | 'particularCondition' | 'scout'
}) => {
  const title = () => {
    switch (type) {
      case 'prefecture':
        return '人気の都道府県からさがす'
      case 'jobCategory':
        return '人気の職種からさがす'
      case 'particularCondition':
        return '働き方からさがす'
      case 'scout':
        return 'こんな仕事のスカウトが届く！'
      default:
        return null
    }
  }
  const switchList = () => {
    switch (type) {
      case 'prefecture':
        return <PopularPrefectureList />
      case 'jobCategory':
        return <PopularJobCategoryList />
      case 'particularCondition':
        return <PopularParticularConditionList />
      case 'scout':
        return <ExplainOfScout />
      default:
        return null
    }
  }
  return (
    <Box bg="white" p="24px 0px">
      <Heading as="h2" fontSize="18px" lineHeight="28px" p="0 16px" mb={4}>
        {title()}
      </Heading>
      <Flex justifyContent="start" alignItems="center">
        <Flex
          className={styles['popular-slider']}
          width="100%"
          maxWidth="640px"
          p="0 16px"
          zIndex={2}
          overflowX="auto"
          sx={{
            ...sliderProperty,
          }}
        >
          {switchList()}
        </Flex>
      </Flex>
      {type === 'scout' && (
        <Text mt="16px" px="16px" textStyle="talent.help" color="text.secondary">
          ※掲載職種・内容は一例です
        </Text>
      )}
    </Box>
  )
}

const ScoutInformation = ({
  scouts,
  isEndStep2,
  isReceivedScout,
  alertText,
  alertButtonValue,
}: {
  scouts: Scouts | null
  isEndStep2: boolean
  isReceivedScout: boolean | undefined
  alertText: string
  alertButtonValue: { text: string; link: string }
}) => {
  if (!isEndStep2) {
    return (
      <Center p="8px 16px" bg="white">
        <Flex gap="10px" bg="brand.yellow-right" p="8px" borderRadius="8px" alignItems="flex-start">
          <ChakraNextImage src={getCharacterIconSource('fullA')} alt="キャラクター" priority />
          <Flex gap="8px" flexDirection="column">
            <Text textStyle="talent.body.lg">{alertText}</Text>
            <Flex justifyContent="right">
              <Button
                as="a"
                href={alertButtonValue.link}
                variant="outline"
                bg="white"
                borderRadius="100px"
                fontSize="12px"
                p="10px 16px"
                w="fit-content"
                h="32px"
              >
                {alertButtonValue.text}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Center>
    )
  }

  if (isReceivedScout) {
    return (
      <Center p="8px 16px" bg="white">
        <Flex gap="10px" bg="brand.blue-right" p="8px" borderRadius="8px" alignItems="flex-start">
          <Image
            src={getCharacterIconSource(scouts?.unread?.length !== 0 ? 'fullC' : 'fullB')}
            alt="キャラクター"
            width={48}
            height={48}
            priority
          />
          <Flex gap="8px" flexDirection="column">
            <Text textStyle="talent.body.lg">{alertText}</Text>
            <Flex justifyContent="right">
              <Button
                as="a"
                href={alertButtonValue.link}
                variant="outline"
                bg="white"
                borderRadius="100px"
                fontSize="12px"
                p="10px 16px"
                w="fit-content"
                h="32px"
              >
                {alertButtonValue.text}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Center>
    )
  }
}

const NotYetLoggedInElm = (index?: number) => {
  return (
    <Center w="full" p="24px" gap="24px" bg="white">
      <VStack w="full" spacing="24px">
        <ChakraLink href="/registration/agent" linkTo="talent" _hover={{ textDecoration: 'none' }}>
          <MainButton
            w="300px"
            h="48px"
            mode="primary"
            fontWeight="bold"
            fontSize="16px"
            data-gtm-click-item={
              index ? (`top_scout_button_${index}` as GTMClickItemName) : undefined
            }
            isShadow
          >
            スカウトを受け取る
          </MainButton>
        </ChakraLink>
        <ChakraLink
          href="/login"
          linkTo="talent"
          data-gtm-click-item={index ? (`top_login_link_${index}` as GTMClickItemName) : undefined}
        >
          <Text textStyle="talent.body.sm" color="text.link" _hover={{ textDecoration: 'none' }}>
            ログイン（登録済みの方）
          </Text>
        </ChakraLink>
      </VStack>
    </Center>
  )
}

export const TopPageWrapper: React.FC<Props> = (props: Props) => {
  const { prefecturesWithJobCount, scouts } = props
  const me = useAtomValue(authMeAtom)
  const isLogin = !!me
  const router = useRouter()
  const guideUrlValue = isLocal && !talentUrl ? `${guideUrl}/guide` : '/guide'
  const { isPcView } = useWindowSize()
  const [isAlert, setIsAlert] = useState(false)
  // STEP2が終わってるかどうかのフラグ
  const isEndStep2 = useMemo(
    () => me?.ready_to_receive_scout_at !== null,
    [me?.ready_to_receive_scout_at],
  )

  // スカウト受信可能かどうか
  const isReceivedScout = useMemo(
    () =>
      !me?.is_inactive_user &&
      !me?.is_scout_limit &&
      (me?.talent_notification_settings.send_sms_of_scout_from_company ||
        me?.talent_notification_settings.send_email_of_scout_from_company ||
        me?.talent_notification_settings.send_line_of_scout_from_company),
    [me],
  )

  const unreadCount = useMemo(() => scouts?.unread?.length ?? 0, [scouts?.unread])

  // functionを挟んでいるためmemo化していない
  const alertText = !isEndStep2
    ? `${me?.first_name}さんにスカウトを届けるため、プロフィール登録をお願いします！ 登録は3分程度で終わります`
    : unreadCount > 0
    ? `${me?.first_name}さんに興味をもった企業から、新しいスカウトが${unreadCount}件届いています！`
    : getDeliveryAnnounceText(
        me?.talent_notification_settings?.scout_delivery_hour ?? DEFAULT_DELIVERY_HOUR,
      )

  const alertButtonValue = useMemo(
    () =>
      !isEndStep2
        ? {
            text: 'プロフィール登録',
            link: '/registration/scout',
          }
        : scouts?.unread?.length !== 0
        ? {
            text: '新着スカウトを確認',
            link: '/scout/feedback',
          }
        : {
            text: '配信時間を変更',
            link: '/account/notification',
          },
    [isEndStep2, scouts?.unread?.length],
  )

  useEffect(() => {
    if (localStorage.getItem(localStorageKey.LOGIN_COMPLETE) === 'true') {
      setIsAlert(true)
      localStorage.removeItem(localStorageKey.LOGIN_COMPLETE)
      setTimeout(() => {
        setIsAlert(false)
      }, 5000)
    }
  }, [])

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={0}
      w="100%"
      maxWidth="640px"
      m="0 auto"
    >
      <Box w="100%">
        <Alert status="success" description="ログインしました。" isVisible={isAlert} />
      </Box>
      <Box w="100%">
        {isLogin ? (
          <>
            <Alert
              status="warning"
              description="プロフィール登録が途中のため、スカウト受信ができない状態になっています。"
              isVisible={!isEndStep2}
            />
            <ScoutInformation
              scouts={scouts}
              isEndStep2={isEndStep2}
              isReceivedScout={isReceivedScout}
              alertText={alertText}
              alertButtonValue={alertButtonValue}
            />
          </>
        ) : (
          <>
            <h1>
              <picture>
                <ChakraNextImage
                  src={FV.src}
                  alt="未経験OK スカウトで正社員 - Zキャリア"
                  priority
                  quality={85}
                />
              </picture>
            </h1>
            <Center w="full" bg="white" pt="24px">
              <VStack w="full" spacing={0}>
                <Flex
                  w="full"
                  p="16px 24px 0"
                  bg="text.link"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  gap="8px"
                >
                  <Text textStyle="talent.heading.lg" color="white">
                    無料登録はこちら！
                  </Text>
                  <Text textAlign="center" textStyle="talent.body.md" color="white">
                    ご回答結果をもとにスカウトが届くので
                    <br />
                    正直な希望・内容をご選択ください
                  </Text>
                </Flex>
                <Center w="full" p="16px" bg="text.link">
                  <Box w="full" bg="white" p="16px" borderRadius="8px" border="1px solid #E2E8F0">
                    <Flex
                      w="100%"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      gap="16px"
                      mb="24px"
                    >
                      <Text textStyle="talent.heading.md" whiteSpace="pre-wrap">
                        {registrationFormAgentToValues[0].title}
                      </Text>
                      <Badge p="4px" borderRadius="4px" color="text.secondary">
                        1 / 8
                      </Badge>
                    </Flex>
                    <VStack w="100%" spacing="8px">
                      {registrationFormAgentToValues[0].options.map((option) => (
                        <OptionButton
                          key={`${option.label}-${option.value}`}
                          label={option.label}
                          value={option.value}
                          onClick={() => router.push(`/registration/agent?step1=${option.value}`)}
                          gtmClickItemName="top_registration_step_0_button"
                          isMobile={!isPcView}
                        />
                      ))}
                    </VStack>
                  </Box>
                </Center>
                <Center w="full" p="24px">
                  <ChakraLink
                    href="/login"
                    linkTo="talent"
                    data-gtm-click-item={'top_login_link_0' as GTMClickItemName}
                  >
                    <Text
                      textStyle="talent.body.sm"
                      color="text.link"
                      _hover={{ textDecoration: 'none' }}
                    >
                      ログイン（登録済みの方）
                    </Text>
                  </ChakraLink>
                </Center>
                <Flex
                  w="full"
                  p="24px"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  gap="8px"
                >
                  <Text textAlign="center" textStyle="talent.body.md">
                    未経験OK求人のスカウトを
                    <br />
                    もらうには登録するだけ！
                  </Text>
                  <ChakraNextImage src={Flow.src} alt="会員登録　スカウトが届く　スカウトを選ぶ" />
                </Flex>
              </VStack>
            </Center>
          </>
        )}
        {/* いいねしたスカウト一覧 */}
        {/* absolute分のmarginを開けている */}
        {isEndStep2 && scouts?.liked && scouts.liked.length !== 0 && (
          <Box bg="white" p="24px 0px">
            <Flex
              px="16px"
              lineHeight="28px"
              alignItems="flex-start"
              justifyContent="space-between"
              mb={4}
            >
              <Heading as="h2" fontSize="18px">
                いいねしたスカウト
              </Heading>
              <ChakraLink
                href="/scout"
                linkTo="talent"
                fontSize="14px"
                color="text.link"
                fontWeight="bold"
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
              >
                もっと見る
              </ChakraLink>
            </Flex>
            <Flex justifyContent="start" alignItems="center">
              <Flex
                className={styles['popular-slider']}
                width="100%"
                maxWidth="640px"
                p="0 16px"
                zIndex={2}
                overflowX="auto"
                sx={{
                  ...sliderProperty,
                }}
              >
                {/* topでみせるスカウトは8件までにする */}
                <LikedScoutList scouts={scouts.liked.slice(0, 8)} />
              </Flex>
            </Flex>
          </Box>
        )}

        {/* guide */}
        <Center p="8px 16px" bg="white">
          <Flex
            w="100%"
            flexDirection="column"
            p="16px"
            gap="16px"
            borderRadius="8px"
            bg="linear-gradient(180deg, #00AA7A 0%, #0EDD7A 100%)"
          >
            <Box>
              <Text
                as="h2"
                textStyle="talent.heading.lg"
                color="white"
                whiteSpace="pre-wrap"
                mb="8px"
              >
                {`はじめての正社員、
ぴったりの仕事がみつかる`}
              </Text>
              <Text textStyle="talent.body.md" color="white" whiteSpace="pre-wrap">
                {`未経験からの正社員就職・転職ならZキャリア！
未経験OKの幅広い求人から、あなたに合った働き方に出会えます。`}
              </Text>
            </Box>

            <Flex w="100%" flexDirection="column" gap="8px">
              <Flex
                bg="white"
                p="12px"
                borderRadius="8px"
                w="100%"
                flexDirection="column"
                gap="12px"
              >
                <ChakraNextImage src={GuideFig1.src} alt="" nextWidth={906} nextHeight={420} />
                <Box>
                  <Text as="h3" textStyle="talent.heading.md" whiteSpace="pre-wrap" mb="8px">
                    {`あなたの「ホンネ」に合わせた
スカウトが届く`}
                  </Text>
                  <Text textStyle="talent.body.md" whiteSpace="pre-wrap">
                    好みにマッチした求人のスカウトが届きます。スカウトは受け取るたびに最適化されます。
                  </Text>
                </Box>
              </Flex>

              <Flex
                bg="white"
                p="12px"
                borderRadius="8px"
                w="100%"
                flexDirection="column"
                gap="12px"
              >
                <ChakraNextImage src={GuideFig2.src} alt="" nextWidth={906} nextHeight={420} />
                <Box>
                  <Text as="h3" textStyle="talent.heading.md" whiteSpace="pre-wrap" mb="8px">
                    {`あなただけの求人情報を
AIが自動で作成`}
                  </Text>
                  <Text textStyle="talent.body.md" whiteSpace="pre-wrap">
                    得意や希望に合わせて、ZキャリアのAIが求人情報の中から知りたいことだけピックアップ。
                  </Text>
                </Box>
              </Flex>
            </Flex>

            <Center>
              <Button
                as="a"
                href="https://zcareer.com/guide/detail/5wikc3w0h8s"
                target="_blank"
                variant="outline"
                alignItems="center"
                w="fit-content"
                h="48px"
                bg="white"
                borderRadius="100px"
                p="10px 32px"
                leftIcon={<ChakraNextImage src={PencilIcon.src} alt="pencil" />}
                data-gtm-click-item={'top_how_to_link' as GTMClickItemName}
              >
                Zキャリア ご利用ガイド
              </Button>
            </Center>
          </Flex>
        </Center>
        {!isLogin && NotYetLoggedInElm(1)}

        {isLogin ? (
          <Flex p="24px 16px" bg="white" flexDirection="column" gap="16px" mb="1px">
            <Flex w="full" alignItems="center" justifyContent="space-between">
              <Box>
                <Heading as="h2" fontSize="18px" lineHeight="28px">
                  就職・転職ガイド
                </Heading>
                <Text textStyle="talent.body.sm">未経験から正社員へのお悩み解決！</Text>
              </Box>
              <ChakraLink
                href={guideUrlValue}
                linkTo="guide"
                fontSize="14px"
                color="text.link"
                fontWeight="bold"
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                isExternal
              >
                もっと見る
              </ChakraLink>
            </Flex>
            <GuideSection />
            <ChakraLink href={guideUrlValue} linkTo="guide" isExternal>
              <ChakraNextImage
                src={GuideBanner.src}
                alt="正社員就職へのお悩み解決！ 就職・転職ガイド"
              />
            </ChakraLink>
          </Flex>
        ) : (
          <>
            <H2Section type="scout" />
            {NotYetLoggedInElm(2)}
            <Center p="24px 16px" bg="white" mb="1px">
              <ChakraLink
                href={guideUrlValue}
                linkTo="guide"
                data-gtm-click-item={'top_guide_button' as GTMClickItemName}
                isExternal
              >
                <ChakraNextImage
                  src={GuideBanner.src}
                  alt="正社員就職へのお悩み解決！ 就職・転職ガイド"
                />
              </ChakraLink>
            </Center>
          </>
        )}

        <Center p="24px 16px 0" bg="white">
          <Flex
            w="100%"
            maxW="400px"
            borderRadius="8px"
            gap="10px"
            p="8px"
            alignItems="center"
            border="1px solid #E2E8F0"
          >
            <ChakraNextImage src={getCharacterIconSource('fullC')} alt="キャラクター" />
            <Text textStyle="talent.body.lg" whiteSpace="pre-wrap">
              {`自分で求人を探したい方はこちら！
都道府県や働き方から検索できます`}
            </Text>
          </Flex>
        </Center>

        {/* 人気エリア・職種・こだわり条件 */}
        <H2Section type="prefecture" />
        <H2Section type="jobCategory" />
        <H2Section type="particularCondition" />

        <Box bg="white" p="24px 16px">
          <Heading as="h2" fontSize="18px" lineHeight="28px" mb={4}>
            全国からさがす
          </Heading>
          <Flex flexDirection="column" gap="16px">
            {prefecturesWithJobCount &&
              prefecturesWithJobCount.regions.map((region) => (
                <Flex flexDirection="column" key={`region-${region.region_master_id}`}>
                  <Heading as="h3" fontSize="14px" lineHeight="20px" mb={2}>
                    {region.name}
                  </Heading>
                  <SimpleGrid columns={2} spacing="8px">
                    {region.prefectures.map((prefecture) =>
                      prefecture.job_count !== 0 ? (
                        <ChakraLink
                          textDecoration="none"
                          href={`/job/workLocationPrefecture_${prefecture.prefecture_master_id}`}
                          linkTo="talent"
                          _hover={{ textDecoration: 'none' }}
                          key={`prefecture-${prefecture.prefecture_master_id}`}
                          data-gtm-click-item={
                            `all_prefecture_card_${prefecture.prefecture_master_id}` as GTMClickItemName
                          }
                        >
                          <Flex
                            py="14px"
                            pr="8px"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom="1px solid #E2E8F0"
                          >
                            <Flex
                              alignItems="center"
                              color={prefecture.job_count !== 0 ? 'text.primary' : '#A0AEC0'}
                            >
                              <LocationMarker
                                category="search"
                                color={prefecture.job_count !== 0 ? '#4A5568' : '#A0AEC0'}
                              />
                              <Text fontWeight={700} fontSize="14px" lineHeight="20px" ml="8px">
                                {prefecture.name}
                              </Text>
                              <Text fontWeight={300} fontSize="10px" lineHeight="20px">
                                {`（${prefecture.job_count}）`}
                              </Text>
                            </Flex>
                            <ChevronRightIcon boxSize="16px" color="#A0AEC0" />
                          </Flex>
                        </ChakraLink>
                      ) : (
                        <Flex
                          py="14px"
                          pr="8px"
                          justifyContent="space-between"
                          alignItems="center"
                          borderBottom="1px solid #E2E8F0"
                          cursor="not-allowed"
                          key={`prefecture-${prefecture.prefecture_master_id}`}
                        >
                          <Flex
                            alignItems="center"
                            color={prefecture.job_count !== 0 ? 'text.primary' : '#A0AEC0'}
                          >
                            <LocationMarker
                              category="search"
                              color={prefecture.job_count !== 0 ? '#4A5568' : '#A0AEC0'}
                            />
                            <Text fontWeight={700} fontSize="14px" lineHeight="20px" ml="8px">
                              {prefecture.name}
                            </Text>
                            <Text fontWeight={300} fontSize="10px" lineHeight="20px">
                              {`（${prefecture.job_count}）`}
                            </Text>
                          </Flex>
                          <ChevronRightIcon boxSize="16px" color="#A0AEC0" />
                        </Flex>
                      ),
                    )}
                  </SimpleGrid>
                </Flex>
              ))}
          </Flex>
        </Box>
      </Box>
      <Flex w="100%" p="24px 0 40px" bg="white" justifyContent="center" alignItems="center">
        <ChakraLink
          textDecoration="none"
          href="/job"
          linkTo="talent"
          _hover={{ textDecoration: 'none' }}
        >
          <Button color="text.link" variant="ghost" fontSize="14px" _hover={{ bg: 'white' }}>
            求人を全件表示
          </Button>
        </ChakraLink>
      </Flex>
    </Flex>
  )
}
