import { Flex, Text } from '@chakra-ui/react'
import Image from 'next/image'

import styles from '@/components/top/top.module.scss'
import { ChakraLink } from '@/modules/components/ChakraLink'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { prefecturesImageSwitcher } from '@/utils/topImageSwitcher'

const PopularPrefectureElm = (data: { name: string; prefectureMasterId: number }) => {
  const { name, prefectureMasterId } = data
  const handleClick = () => {
    // TODO: ここでGTMのイベントを発火させる
  }
  return (
    <ChakraLink
      className={styles['prefecture-box']}
      cursor="pointer"
      onClick={() => handleClick()}
      textDecoration="none"
      href={`/job/workLocationPrefecture_${prefectureMasterId}`}
      linkTo="talent"
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={`popular_prefecture_card_${prefectureMasterId}` as GTMClickItemName}
    >
      <Image
        alt={name}
        className={styles['prefecture-image']}
        width={160}
        height={120}
        src={prefecturesImageSwitcher(prefectureMasterId)}
      />
      <Flex alignItems="center">
        <Text fontSize="11px" fontWeight={600} lineHeight="20px">
          {name}
        </Text>
      </Flex>
    </ChakraLink>
  )
}

export const PopularPrefectureList = () => {
  const displayPrefectures = [
    { name: '東京都', prefectureMasterId: 21 },
    { name: '神奈川県', prefectureMasterId: 22 },
    { name: '埼玉県', prefectureMasterId: 23 },
    { name: '大阪府', prefectureMasterId: 44 },
    { name: '千葉県', prefectureMasterId: 24 },
    { name: '兵庫県', prefectureMasterId: 45 },
    { name: '福岡県', prefectureMasterId: 71 },
    { name: '愛知県', prefectureMasterId: 39 },
    { name: '宮城県', prefectureMasterId: 14 },
    { name: '京都府', prefectureMasterId: 43 },
  ]
  return (
    <Flex className={styles['popular-prefecture-item-boxes']}>
      {displayPrefectures.map((v) => (
        <PopularPrefectureElm
          key={`popular-prefecture-${v.prefectureMasterId}`}
          name={v.name}
          prefectureMasterId={v.prefectureMasterId}
        />
      ))}
    </Flex>
  )
}
