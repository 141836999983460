import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { getPrefecturesWithJobCounts } from '@/api/masterAPi'
import { getAllScoutApi } from '@/api/scoutApi'
import { TopPageWrapper } from '@/components/top/TopPageWrapper'
import { appUrl } from '@/config'
import { META } from '@/const/meta'
import { authCookieName } from '@/hooks/useAuth'
import AppLayout from '@/layout/AppLayout'
import serverSideLogger from '@/modules/utils/serverSideLogger'
import { PrefecturesWithJobCount } from '@/oas/public/api'
import { Scouts } from '@/oas/talent/api'
import Error from '@/pages/_error'

type Props = {
  data: {
    jobs: PrefecturesWithJobCount | null
    scouts: Scouts | null
  }
  status: number
}

const Home: React.FC<Props> = (props: Props) => {
  const { data, status } = props
  const router = useRouter()
  if (status !== 200) {
    return <Error statusCode={status} />
  }

  const { title } = META.home
  const { description } = META.home
  const canonicalUrl = `${appUrl}${router.asPath.split('?')[0]}`

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <AppLayout>
        <TopPageWrapper prefecturesWithJobCount={data.jobs} scouts={data.scouts} />
      </AppLayout>
    </div>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const authCookie = context.req.cookies?.[authCookieName]

    // 並列で実行する処理をPromise.allSettledでまとめる
    const [prefecturesRes, scoutRes] = await Promise.allSettled([
      getPrefecturesWithJobCounts(),
      // まず、ログイン状態を確認して、ログインしている場合はログインユーザー情報を取得
      authCookie ? getAllScoutApi(undefined, authCookie) : Promise.resolve(null),
    ])

    // prefecturesResでエラーが発生した場合は、エラーを投げる
    if (prefecturesRes.status === 'rejected') {
      throw prefecturesRes.reason
    }
    return {
      props: {
        data: {
          jobs: prefecturesRes.status === 'fulfilled' ? prefecturesRes.value.data : null,
          scouts: scoutRes.status === 'fulfilled' ? scoutRes.value?.data ?? null : null,
        },
        status: prefecturesRes.value.status,
      },
    }
  } catch (err) {
    serverSideLogger.error({
      err,
    })
    throw err
  }
}

export default Home
