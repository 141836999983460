import job_categories_id_1 from '@/assets/img/job_categories/job_categories_id_1.jpg'
import job_categories_id_2 from '@/assets/img/job_categories/job_categories_id_2.jpg'
import job_categories_id_3 from '@/assets/img/job_categories/job_categories_id_3.jpg'
import job_categories_id_6 from '@/assets/img/job_categories/job_categories_id_6.jpg'
import job_categories_id_9 from '@/assets/img/job_categories/job_categories_id_9.jpg'
import job_categories_id_11 from '@/assets/img/job_categories/job_categories_id_11.jpg'
import job_categories_id_13 from '@/assets/img/job_categories/job_categories_id_13.jpg'
import job_categories_id_14 from '@/assets/img/job_categories/job_categories_id_14.jpg'
import has_dormitory from '@/assets/img/particular_condition/particular_condition_has_dormitory.png'
import has_weekends_and_national_holiday from '@/assets/img/particular_condition/particular_condition_has_weekends_and_national_holiday.png'
import is_allowed_no_prior_full_time_experience from '@/assets/img/particular_condition/particular_condition_is_allowed_no_prior_full_time_experience.png'
import is_allowed_to_commute_by_car from '@/assets/img/particular_condition/particular_condition_is_allowed_to_commute_by_car.png'
import is_available_to_reduce_working_time from '@/assets/img/particular_condition/particular_condition_is_available_to_reduce_working_time.png'
import is_dress_code_free from '@/assets/img/particular_condition/particular_condition_is_dress_code_free.png'
import is_listed_company from '@/assets/img/particular_condition/particular_condition_is_listed_company.png'
import is_more_than_120_days_off_per_year from '@/assets/img/particular_condition/particular_condition_is_more_than_120_days_off_per_year.png'
import is_no_need_to_relocate from '@/assets/img/particular_condition/particular_condition_is_no_need_to_relocate.png'
import is_overtime_under_20 from '@/assets/img/particular_condition/particular_condition_is_overtime_under_20.png'
import prefectures_id_14 from '@/assets/img/prefectures/prefectures_id_14.png'
import prefectures_id_21 from '@/assets/img/prefectures/prefectures_id_21.png'
import prefectures_id_22 from '@/assets/img/prefectures/prefectures_id_22.png'
import prefectures_id_23 from '@/assets/img/prefectures/prefectures_id_23.png'
import prefectures_id_24 from '@/assets/img/prefectures/prefectures_id_24.png'
import prefectures_id_39 from '@/assets/img/prefectures/prefectures_id_39.png'
import prefectures_id_43 from '@/assets/img/prefectures/prefectures_id_43.png'
import prefectures_id_44 from '@/assets/img/prefectures/prefectures_id_44.png'
import prefectures_id_45 from '@/assets/img/prefectures/prefectures_id_45.png'
import prefectures_id_71 from '@/assets/img/prefectures/prefectures_id_71.png'

export function prefecturesImageSwitcher(id: number) {
  const image =
    id === 14
      ? prefectures_id_14
      : id === 21
      ? prefectures_id_21
      : id === 22
      ? prefectures_id_22
      : id === 23
      ? prefectures_id_23
      : id === 24
      ? prefectures_id_24
      : id === 39
      ? prefectures_id_39
      : id === 43
      ? prefectures_id_43
      : id === 44
      ? prefectures_id_44
      : id === 45
      ? prefectures_id_45
      : id === 71
      ? prefectures_id_71
      : null
  return image ? image.src : ''
}

export function jobCategoryImageSwitcher(id: number) {
  const image =
    id === 1
      ? job_categories_id_1
      : id === 11
      ? job_categories_id_11
      : id === 13
      ? job_categories_id_13
      : id === 14
      ? job_categories_id_14
      : id === 2
      ? job_categories_id_2
      : id === 3
      ? job_categories_id_3
      : id === 6
      ? job_categories_id_6
      : id === 9
      ? job_categories_id_9
      : null
  return image ? image.src : ''
}

export function particularConditionImageSwitcher(key: string) {
  const image =
    key === 'has_dormitory'
      ? has_dormitory
      : key === 'has_weekends_and_national_holiday'
      ? has_weekends_and_national_holiday
      : key === 'is_allowed_to_commute_by_car'
      ? is_allowed_to_commute_by_car
      : key === 'is_allowed_no_prior_full_time_experience'
      ? is_allowed_no_prior_full_time_experience
      : key === 'is_available_to_reduce_working_time'
      ? is_available_to_reduce_working_time
      : key === 'is_dress_code_free'
      ? is_dress_code_free
      : key === 'is_listed_company'
      ? is_listed_company
      : key === 'is_more_than_120_days_off_per_year'
      ? is_more_than_120_days_off_per_year
      : key === 'is_no_need_to_relocate'
      ? is_no_need_to_relocate
      : key === 'is_overtime_under_20'
      ? is_overtime_under_20
      : null
  return image ? image.src : ''
}
