import { Flex, Link as ChakraLink, Text } from '@chakra-ui/react'
import Image from 'next/image'

import styles from '@/components/top/top.module.scss'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { particularConditionImageSwitcher } from '@/utils/topImageSwitcher'

const PopularParticularConditionElm = (data: {
  name: string
  particularConditionMasterId: number
  imageKey: string
}) => {
  const { name, particularConditionMasterId, imageKey } = data
  const handleClick = () => {
    // TODO: ここでGTMのイベントを発火させる
  }
  return (
    <ChakraLink
      className={styles['particular-condition-box']}
      cursor="pointer"
      onClick={() => handleClick()}
      textDecoration="none"
      href={`/job/particularCondition_${particularConditionMasterId}`}
      _hover={{ textDecoration: 'none' }}
      data-gtm-click-item={
        `popular_appeal_point_card_${particularConditionMasterId}` as GTMClickItemName
      }
    >
      <Image
        alt={name}
        className={styles['particular-condition-image']}
        width={120}
        height={80}
        src={particularConditionImageSwitcher(imageKey)}
      />
      <Flex alignItems="center">
        <Text fontWeight={600} fontSize="11px" lineHeight="20px">
          {name}
        </Text>
      </Flex>
    </ChakraLink>
  )
}

export const PopularParticularConditionList = () => {
  const displayJobCategories = [
    {
      name: '土日祝休み',
      particularConditionMasterId: 1,
      imageKey: 'has_weekends_and_national_holiday',
    },
    {
      name: '年間休日120日以上',
      particularConditionMasterId: 2,
      imageKey: 'is_more_than_120_days_off_per_year',
    },
    { name: '月残業20時間以内', particularConditionMasterId: 3, imageKey: 'is_overtime_under_20' },
    { name: '転勤なし', particularConditionMasterId: 4, imageKey: 'is_no_need_to_relocate' },
    {
      name: '服装自由',
      particularConditionMasterId: 5,
      imageKey: 'is_dress_code_free',
    },
    { name: '上場企業', particularConditionMasterId: 6, imageKey: 'is_listed_company' },
    {
      name: '車通勤OK',
      particularConditionMasterId: 7,
      imageKey: 'is_allowed_to_commute_by_car',
    },
    {
      name: '時短勤務可',
      particularConditionMasterId: 8,
      imageKey: 'is_available_to_reduce_working_time',
    },
    {
      name: '社員寮あり',
      particularConditionMasterId: 9,
      imageKey: 'has_dormitory',
    },
    {
      name: '正社員経験なしOK',
      particularConditionMasterId: 10,
      imageKey: 'is_allowed_no_prior_full_time_experience',
    },
  ]
  return (
    <Flex className={styles['popular-particular-condition-item-boxes']}>
      {displayJobCategories.map((v) => (
        <PopularParticularConditionElm
          key={`particular-condition-${v.particularConditionMasterId}`}
          name={v.name}
          particularConditionMasterId={v.particularConditionMasterId}
          imageKey={v.imageKey}
        />
      ))}
    </Flex>
  )
}
